<template>
  <div>
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">LES PRODUITS COMMERCIALISÉS</h2>
    </v-row>
    <v-divider class="mt-3 mb-6"></v-divider>
    <v-row align="center" class="">
      <v-col cols="12" md="3">
        <v-text-field v-model="selectedProduct" label="Rechercher dans la liste"></v-text-field>
      </v-col>
      <v-col cols="12" md="1" align-self="center"> </v-col>
      <v-col cols="12" md="3" align-self="end" class="ml-5"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" v-for="(product, index) in filteredProductItems" v-bind:key="product.id">
        <v-card
          @mouseover="product.hovered = true"
          @mouseleave="product.hovered = false"
          class="rounded-xl align-center d-flex"
          outlined
          elevation="3"
          width="200"
          height="200"
          link
          :href="`/services/products/items?id=${product.id}`"
        >
          <v-card-text class="text-center pa-5">
            <i :class="['fa-solid', `fa-${product.icon}`, 'fa-3x EoleBlue--text']"></i>
            <p class="mt-2">{{ product.name }}</p>
          </v-card-text>
          <div class="hover-overlay rounded-xl">
            <span class="overlay-text">Consulter la fiche produit {{ product.name }}</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
export default {
  data: () => ({
    dialogProduct: [],
    productsAgency: [],
    productsProfessionnalCount: 0,
    productsParticularCount: 0,
    selectedProduct: "",
  }),
  async created() {
    this.fetchAgencyProducts()
  },
  computed: {
    filteredProductItems() {
      // Filtrer les produits en fonction du texte saisi dans v-text-field
      const searchText = this.selectedProduct.toLowerCase()
      return this.productsAgency.filter(product => product.name.toLowerCase().includes(searchText))
    },
  },

  methods: {
    async fetchAgencyProducts() {
      this.productsAgency = []

      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          data.forEach(element => {
            element.particular === 1 ? this.productsParticularCount++ : this.productsParticularCount
            element.professionnal === 1 ? this.productsProfessionnalCount++ : this.productsProfessionnalCount
            this.productsAgency.push({
              id: element.id,
              name: element.name,
              sponsoring: element.sponsoring,
              professionnal: element.professionnal,
              particular: element.particular,
              insurers: element.insurers,
              hovered: false,
              icon: element.icon,
            })
          })
        }
      } catch (e) {
        console.error(e)

        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}
.overlay-text {
  color: white;
  font-size: 12px !important;
  text-align: center;
  padding: 5px;
}
.v-card:hover .hover-overlay {
  opacity: 1;
}
</style>
